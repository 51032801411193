<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg
      v-if="exceptionMsg"
      :msg="exceptionMsg"
      :image="exceptionImg"
    />

    <QrCodeSessionToScan
      v-if="!exceptionMsg"
      :qrCodeSession="qrCodeSessions.qrCodeSession"
      v-on:regenerate="generateMyQrCode()"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import createToastMixin from "@/utils/create-toast-mixin";
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import QrCodeSessionToScan from "@/components/qrCodeSessions/QrCodeSessionToScan.vue";
import QrCodeSessions from "@/models/qrCodeSessions/QrCodeSessions";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    QrCodeSessionToScan,
  },
  computed: {
    ...mapGetters(["userAuthorizeToken"]),
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: "illustrator-something-wrong.svg",
      language: localStorage.getItem("userLanguage") || "ar",
      qrCodeSessions: new QrCodeSessions(),
    };
  },
  methods: {
    async generateMyQrCode() {
      this.isLoading = true;
      try {
        const response =
          await this.qrCodeSessions.qrCodeSession.generateMyQrCode(
            this.language,
            this.userAuthorizeToken
          );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.qrCodeSessions.qrCodeSession.fillData(
            response.data.qrCodeSession
          );
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.qrCodeSessions.qrCodeSession.setInitialValue();
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.qrCodeSessions.qrCodeSession.setInitialValue();
          this.$store.dispatch("logoutUser");
        } else {
          this.qrCodeSessions.qrCodeSession.setInitialValue();
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.qrCodeSessions.qrCodeSession.setInitialValue();
        this.exceptionMsg = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.generateMyQrCode();
  },
};
</script>

<style scoped lang="scss"></style>
