import axios from "axios";
import BaseEntityModel from "./../general/BaseEntityModel";

export default class QrCodeSession extends BaseEntityModel {
  constructor() {
    super();
    this.setInitialValue();
  }
  setInitialValue() {
    this.qrCodeSessionToken = "";
    this.startQrCodeSessionDateTime = "";
    this.dateTimeNowUTC = "";
    this.dateNow = "";
    this.timeNow = "";
    this.startQrCodeSessionDate = "";
    this.startQrCodeSessionTime = "";
    this.endQrCodeSessionDateTime = "";
    this.endQrCodeSessionDate = "";
    this.endQrCodeSessionTime = "";
    this.durationQrCodeSession = "";
    this.activationStatus = "";
    this.enquiryStatus = "";
    this.buyStatus = "";
    this.cardToken = "";
    this.cardData = "";
    this.countdownTimerStart = "";
    this.countdownTimerEnd = "";
    this.countdownDuration = "";
  }
  fillData(data) {
    if (data) {
      this.fillDataBaseEntityModelBaseEntityModel(data);
      this.qrCodeSessionToken = data.qrCodeSessionToken;
      this.startQrCodeSessionDateTime = data.startQrCodeSessionDateTime;
      this.dateTimeNowUTC = data.dateTimeNowUTC;
      this.dateNow = data.dateNow;
      this.timeNow = data.timeNow;
      this.startQrCodeSessionDate = data.startQrCodeSessionDate;
      this.startQrCodeSessionTime = data.startQrCodeSessionTime;
      this.endQrCodeSessionDateTime = data.endQrCodeSessionDateTime;
      this.endQrCodeSessionDate = data.endQrCodeSessionDate;
      this.endQrCodeSessionTime = data.endQrCodeSessionTime;
      this.durationQrCodeSession = data.durationQrCodeSession;
      this.activationStatus = data.activationStatus;
      this.enquiryStatus = data.enquiryStatus;
      this.buyStatus = data.buyStatus;
      this.cardToken = data.cardToken;
      this.cardData = data.cardData;
      this.countdownTimerStart = `${data.startQrCodeSessionDate} ${data.startQrCodeSessionTime}`;
      this.countdownTimerEnd = `${data.endQrCodeSessionDate} ${data.endQrCodeSessionTime}`;
      // this.countdownTimerEnd = this.getCountdownTimerEnd(
      //   `${data.dateNow} ${data.timeNow}`,
      //   `${data.endQrCodeSessionDate} ${data.endQrCodeSessionTime}`
      // );
      this.countdownDuration =
        new Date(data.endQrCodeSessionDateTime) - new Date(data.dateTimeNowUTC);
    }
  }

  async enquiryQrCode(language, userAuthorizeToken, userToken) {
    let qrCodeSessionTokenPattern = userToken + "**" + this.qrCodeSessionToken;
    return await axios.get(
      `/api/QrCodeSessions/EnquiryQrCodeVCD?language=${language}&userAuthorizeToken=${userAuthorizeToken}&timeZoneToken=&qrCodeSessionToken=${qrCodeSessionTokenPattern}`
    );
  }

  async generateMyQrCode(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
    };

    return await axios.post(`/api/QrCodeSessions/GenerateMyQrCodeVCD`, data);
  }

  getCountdownTimerEnd(startDateTime, endDateTime) {
    let start = new Date(startDateTime);
    let end = new Date(endDateTime);
    let difference = end - start;
    let now = new Date();
    let countdownTimerEnd = new Date(now.getTime() + difference);

    // let countdownTimerEndF = `${countdownTimerEnd.getFullYear()}/0${countdownTimerEnd.getMonth()+1}/${countdownTimerEnd.getDate()} 04:${countdownTimerEnd.getMinutes()}:${countdownTimerEnd.getSeconds()} PM`;
    // let countdownTimerEndF = `${countdownTimerEnd.getFullYear()}/${
    //   countdownTimerEnd.getMonth() + 1
    // }/${countdownTimerEnd.getDate()} ${countdownTimerEnd.getHours()}:${countdownTimerEnd.getMinutes()}:${countdownTimerEnd.getSeconds()}`;

    // console.log(`start: ${start}`);
    // console.log(`end: ${end}`);
    // console.log(`difference: ${difference}`);
    // console.log(`now: ${now}`);
    // console.log(`countdownTimerEnd: ${countdownTimerEnd}`);
    // console.log(`countdownTimerEndFormated: ${countdownTimerEndF}`);

    return countdownTimerEnd;
  }
}
