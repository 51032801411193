import QrCodeSession from "./QrCodeSession";
import QrCodeSessionsFilter from "./QrCodeSessionsFilter";

export default class QrCodeSessions {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.status = 0;
    this.msg = "";
    this.qrCodeSessionsData = null;
    this.qrCodeSession = new QrCodeSession();
    this.filterData = new QrCodeSessionsFilter();
  }
  fillData(data) {
    this.status = data.status;
    this.msg = data.msg;
    this.qrCodeSessionsData = data.qrCodeSessions;
  }
}
