<template>
  <!-- class="icon-lg" -->
  <div class="qr-container">
    <qrcode-vue
      class=""
      :value="qrCodeSession.qrCodeSessionToken"
      :size="250"
      level="H"
      render-as="svg"
    />

    <div class="qr-countdown">
      <vue-countdown-timer
        :start-time="qrCodeSession.countdownTimerStart"
        :end-time="qrCodeSession.countdownTimerEnd"
      >
        <template slot="countdown" slot-scope="scope">
          <span>{{ scope.props.days }}:</span>
          <span>{{ scope.props.hours }}:</span>
          <span>{{ scope.props.minutes }}:</span>
          <span>{{ scope.props.seconds }}</span>
        </template>

        <template slot="end-text">
          <button class="btn btn-primary" @click="regenerate()">
            {{ $t("QR.regenerate") }}
          </button>
        </template>
      </vue-countdown-timer>
    </div>

    <!-- <CountDownTimer :duration="qrCodeSession.countdownDuration" /> -->
  </div>
</template>

<script>
// import CountDownTimer from "./../general/CountDownTimer.vue";
import QrcodeVue from "qrcode.vue";

export default {
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["qrCodeSession"],
  components: {
    QrcodeVue,
    // CountDownTimer,
  },
  methods: {
    regenerate() {
      this.$emit("regenerate");
    },
  },
};
</script>
